import React, { useEffect } from "react";
import MainPage from "./pages/MainPage";
import { ErrorBoundary } from "@sentry/react";
import Auth0ErrorPage from "./pages/Auth0ErrorPage";
import { LoadingPage } from "./pages/LoadingPage";
import { useAuth0 } from "@auth0/auth0-react";
import i18next from "i18next";

const App: React.FC = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  const detectLanguageFromUrl = (): string => {
    const urlPath = window.location.href;
    if (urlPath.includes("?lang=en-US")) {
      return "en-US";
    }
    return "es-ES"; // default
  };

  useEffect(() => {
    const detectedLanguage = detectLanguageFromUrl();
    i18next
      .changeLanguage(detectedLanguage)
      .catch((err) => console.error("Failed to change language:", err));
  }, []);

  const env = import.meta.env.VITE_ENV || "prod";

  if (env === "stg" || env === "dev") {
    if (isLoading) {
      return <LoadingPage />;
    }

    if (error) {
      return <Auth0ErrorPage error={error} />;
    }

    if (!isAuthenticated) {
      loginWithRedirect().then();
      return null;
    }

    return (
      <ErrorBoundary>
        <MainPage />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <MainPage />
    </ErrorBoundary>
  );
};

export default App;
