import React from "react";
import SearchBar from "../components/molecules/SearchBar";
import { PoweredByAIFindr } from "../assets/PoweredByAIFindr";
import { useTranslation } from "react-i18next";

const SearchPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <nav className="w-full py-12 text-white">
        <div className="container mx-auto text-center"></div>
      </nav>

      <div
        className="flex-grow flex flex-col items-center w-full px-5"
        style={{ paddingTop: "20vh" }}
      >
        <div className="text-center space-y-6 w-full max-w-[680px] mx-auto">
          <h1
            className="md:text-[40px] text-[24px] md:leading-[48px] leading-[32px] font-[600] decoration-skip-ink-none underline-offset-from-font text-[#001846]"
            style={{
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "48px",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            {t("translation.title-search")}
          </h1>
          <SearchBar isInternalSearch={false} />
        </div>
        <div className="mt-0 flex justify-center">
          <PoweredByAIFindr width="124" height="14" />
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
